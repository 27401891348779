<template>
  <div>
    <Crumbs></Crumbs>
    <div class="dictMain">
      <div class="mainList box-shadow">
        <div class="mainList_operation clearfloat">
          <div class="mainList_operation_inline_block">
            <div class="mainList_operation_search">
              <el-select
                v-model="parameter.dictType"
                clearable
                filterable
                placeholder="字典名称"
                class="ipt_width">
                <el-option
                  v-for="item in dictList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.dictType">
                </el-option>
              </el-select>
              <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
                >搜索</el-button
              >
            </div>
          </div>
          <div class="fr">
            <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
          </div>
        </div>
        <div class="mainList_content">
          <div class="mainList_content_sub">
            <el-table
              ref="multipleTable"
              border
              :data="tableData"
              tooltip-effect="dark"
              height="string"
              :row-style="rowStyle"
              @row-click="rowClick"
              v-loading="loading">
              <el-table-column align="center" prop="dictName" label="字典名称"></el-table-column>
              <el-table-column prop="dictType" align="center" label="字典代码"></el-table-column>
              <el-table-column label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="text_Edit_Bgc"
                    @click.stop="edit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    class="text_Remove_Bgc"
                    size="small"
                    @click.stop="del(scope)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="pageChangeHandler"
              @size-change="handleSizeChange"
              :current-page="parameter.pageNow"
              :page-size="parameter.pageSize"
              prev-text="上一页"
              next-text="下一页"
              :total="parameter.total">
            </el-pagination>
          </div>
        </div>
      </div>
      <Sub class="box-shadow" :data="activeData" />
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>
<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Sub: () => import('./Sub.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        dictType: '',
      },
      activeData: {},
      options: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      editDialogLoading: false,
      activeId: null,
      dictList: [],
    }
  },
  created() {
    this.getData()
    this.$api.dictClassify
      .listSysDictType({
        pageNow: 1,
        pageSize: 999999,
      })
      .then(res => {
        this.dictList = res.data.records ? res.data.records : []
      })
      .catch(err => {
        console.log(err)
      })
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    rowClick(row) {
      this.activeData = row
    },
    rowStyle(res) {
      if (res.row.dictType == this.activeData.dictType) {
        return {
          background: '#f5f7fa',
        }
      } else {
        return {}
      }
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    add() {
      this.options = {}
      this.showEditDialog = true
    },
    edit(row) {
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    getData() {
      this.loading = true

      this.$api.dictClassify
        .listSysDictType(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.parameter.total = Number(res.data.total)
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.dictClassify
            .del(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')

              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNow >= this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNow > 1) {
                this.parameter.pageNow--
              }
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.dictMain {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 45px);
  padding: 20px;
  .mainList {
    background: #fff;
    border-radius: 8px;
    .mainList_operation {
      padding: 0;
    }
    .mainList_content_sub {
      padding: 0;
    }
    &:first-child {
      width: 45%;
    }
    &:last-child {
      width: calc(55% - 20px);
    }
  }
}
</style>
